import classNames from 'classnames';
import Link from 'next/link';
import React, { useContext } from 'react';
import { FiArrowRight } from 'react-icons/fi';
import { usePageLoading } from '../../hooks/usePageLoading';
import { usePropertiesFilterLink } from '../../hooks/usePropertiesFilterLink';
import { CommonPageDataProps } from '../../types';
import { TypeFooter } from '../../types/generated/page-contents';
import { getAgentSlug, isRealAgent } from '../../utils/AgentUtils';
import { getThemeTextColorClassName } from '../../utils/DisplayHelpers';
import { ThemeContext } from '../ThemeProvider';
import { LegaleseElement } from '../../openapi/wanderer';
import { OfficesResponse } from '../../openapi/bff';
import LayoutRedesigned from '../LayoutRedesigned';
import FooterDisclaimerRedesigned from '../FooterDisclaimerRedesigned';
import FooterTop from '../FooterTop';
import AgentBottomLinks from './AgentBottomLinks';
import AgentHrCallToAction from './AgentHrCallToAction';
import AgentNav, { AgentNavProps } from './AgentNav';

interface AgentLayoutProps extends AgentNavProps, CommonPageDataProps {
  showFooterCTA?: boolean;
  legalese?: LegaleseElement[];
  children: React.ReactNode;
  footerContent: TypeFooter;
  allOffices: OfficesResponse;
}

const AgentLayoutRedesigned: React.FC<AgentLayoutProps> = ({
  agentWebsiteResponse,
  variant,
  showFooterCTA = false,
  children,
  countriesWithStates,
  legalese,
  footerContent,
  allOffices,
  agentSlug,
  bffAgentSlugResponse,
}) => {
  const referUrl = `/${getAgentSlug(agentWebsiteResponse, agentSlug)}/refer`;

  const colorTheme = useContext(ThemeContext);

  const loading = usePageLoading();

  const propertiesLink = usePropertiesFilterLink(
    agentWebsiteResponse,
    agentSlug
  );

  if (isRealAgent(agentWebsiteResponse?.agent)) {
    return (
      <LayoutRedesigned
        countriesWithStates={countriesWithStates}
        footerContent={footerContent}
        allOffices={allOffices}
      >
        {children}
      </LayoutRedesigned>
    );
  }

  return (
    <div className='bg-white'>
      <AgentNav
        agentWebsiteResponse={agentWebsiteResponse}
        variant={variant}
        loading={loading}
        agentSlug={agentSlug}
        bffAgentSlugResponse={bffAgentSlugResponse}
      />
      {children}
      <AgentBottomLinks
        buyAndSellLink={propertiesLink}
        agentEmail={agentWebsiteResponse?.agent?.email}
        agentSlug={getAgentSlug(agentWebsiteResponse, agentSlug)}
        licensedStates={
          agentWebsiteResponse?.agent?.licensed_states_array || []
        }
        agentPhone={agentWebsiteResponse?.agent?.phone_1_display}
        social={
          agentWebsiteResponse?.agent?.social || {
            personal_website_url: bffAgentSlugResponse?.personalWebsiteURL,
            instagram_profile_url: bffAgentSlugResponse?.instagramURL,
            twitter_profile_url: bffAgentSlugResponse?.twitterURL,
            facebook_profile_url: bffAgentSlugResponse?.facebookURL,
            linkedin_profile_url: bffAgentSlugResponse?.linkedInURL,
            zillow_profile_url: bffAgentSlugResponse?.zillowURL,
            internal_website_url: bffAgentSlugResponse?.personalWebsiteURL,
            yelp_profile_url: bffAgentSlugResponse?.yelpURL,
            google_business_profile_url:
              bffAgentSlugResponse?.googleBusinessProfileURL,
          }
        }
      />
      <div className='w-full bg-gray-50'>
        <div
          className={classNames(
            'w-full max-w-6xl mx-auto px-6 md:px-0 flex flex-col space-y-6 pb-20',
            { 'pt-20': !showFooterCTA }
          )}
        >
          {showFooterCTA &&
            agentWebsiteResponse?.website?.show_agent_referral_page && (
              <div className='py-20'>
                <AgentHrCallToAction
                  TitleComponent={
                    <p className='font-primary-light text-2xl text-dark'>
                      <span className='font-primary-medium'>
                        Are you an agent? Join me at Real!
                      </span>{' '}
                      Real is a technology-powered brokerage that offers agents
                      flexibility, mobile technology, a high split, equity, and
                      more. If you&apos;d like to learn more,{' '}
                      <Link href={referUrl}>
                        <a
                          className={classNames(
                            getThemeTextColorClassName(colorTheme)
                          )}
                        >
                          visit here
                        </a>
                      </Link>
                    </p>
                  }
                  ButtonRightIcon={<FiArrowRight size={18} color='#fff' />}
                  buttonText='Join Real'
                  buttonActionUrl={referUrl}
                  variant={colorTheme}
                />
              </div>
            )}
        </div>
      </div>
      <div className='bg-footer-bg py-10 md:py-20 md:px-28 w-full'>
        <FooterTop />
        <FooterDisclaimerRedesigned
          disclaimers={legalese}
          brokerLicenses={agentWebsiteResponse?.broker_licenses || []}
          countriesWithStates={countriesWithStates}
          allOffices={allOffices}
        />
      </div>
    </div>
  );
};

export default AgentLayoutRedesigned;
