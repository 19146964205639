import classNames from 'classnames';
import Link from 'next/link';
import React, { useState } from 'react';
import { MdMenu, MdOutlineClose } from 'react-icons/md';
import { usePropertiesFilterLink } from '../../hooks/usePropertiesFilterLink';
import {
  BFFAgentSlugResponse,
  RealApiAgentWebsiteResponse,
} from '../../types/real-api-types';
import ContactCard from '../ContactCard';
import Modal from '../Modal';
import PageTransitionLoader from '../PageTransitionLoader';
// import { ThemeContext } from '../ThemeProvider';
import { getAgentSlug } from '../../utils/AgentUtils';
import AgentNavLink from './AgentNavLink';
// import Button from './Button';

export interface AgentNavProps {
  agentWebsiteResponse: RealApiAgentWebsiteResponse;
  variant?: 'primary' | 'transparent';
  loading?: boolean;
  agentSlug?: string;
  bffAgentSlugResponse?: BFFAgentSlugResponse;
}

const AgentNav: React.FC<AgentNavProps> = ({
  agentWebsiteResponse,
  variant = 'primary',
  loading,
  agentSlug,
  bffAgentSlugResponse,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isContactingMe, setIsContactingMe] = useState<boolean>(false);
  const bffAgentName =
    bffAgentSlugResponse?.displayName ?? bffAgentSlugResponse?.fullName;
  // const colorTheme = useContext(ThemeContext);

  const propertiesLink = usePropertiesFilterLink(
    agentWebsiteResponse,
    agentSlug
  );

  return (
    <nav
      className={classNames(
        'flex items-center shrink justify-between flex-wrap w-full h-12',
        {
          'bg-white border-b border-gray-200 z-50 sticky top-0':
            variant === 'primary',
          'md:border-b-0 border-b border-gray-200 md:bg-gradient-to-b from-black-600 to-transparent bg-white md:bg-transparent fixed top-0 z-50':
            variant === 'transparent',
        }
      )}
      title='agent-nav'
    >
      <div className='flex items-center h-full w-full px-2 relative'>
        <Link
          href={{
            pathname: '/[agentSlug]',
            query: {
              agentSlug: getAgentSlug(agentWebsiteResponse, agentSlug),
            },
          }}
        >
          <a className='overflow-hidden'>
            <div className='flex flex-col flex-nowrap cursor-pointer'>
              <div className='flex flex-col md:flex-row md:space-x-3 md:items-end'>
                <h5
                  className={classNames(
                    'truncate leading-6 md:leading-6 font-primary-medium text-xl md:text-2xl',
                    {
                      'text-black': variant === 'primary',
                      'md:text-white text-black': variant === 'transparent',
                    }
                  )}
                >
                  {agentWebsiteResponse?.agent?.displayName || bffAgentName}
                </h5>
                <p className='truncate leading-4 md:leading-6 font-primary-light text-base md:text-xl text-dark md:text-white'>
                  {agentWebsiteResponse?.website?.site_title || ''}
                </p>
              </div>
              <span className='hidden md:flex flex-row flex-nowrap items-baseline h-5'>
                <img
                  src='/img/real_logo.svg'
                  alt='logo'
                  className={classNames('h-4', {
                    'md:invert invert-0': variant === 'transparent',
                  })}
                />
                <span
                  className={classNames('font-primary-regular text-sm ml-3', {
                    'text-black': variant === 'primary',
                    'md:text-white text-black': variant === 'transparent',
                  })}
                >
                  Real Broker, LLC
                </span>
              </span>
            </div>
          </a>
        </Link>
        <div className='flex flex-1 items-center justify-end h-full'>
          <div className='md:flex md:flex-row md:items-center md:justify-end h-full hidden'>
            <AgentNavLink
              title='Buy & Rent'
              href={propertiesLink}
              variant={variant}
            />
            <AgentNavLink
              title='About'
              href={`/${getAgentSlug(agentWebsiteResponse, agentSlug)}/about`}
              variant={variant}
            />
          </div>
          {/* <Button
            title='Contact Me'
            variant={colorTheme}
            onClick={() => setIsContactingMe(true)}
          /> */}
          {isOpen ? (
            <MdOutlineClose
              fontSize={30}
              color='grey'
              className='md:hidden ml-3'
              onClick={() => setIsOpen(false)}
            />
          ) : (
            <MdMenu
              fontSize={30}
              color='grey'
              className='md:hidden ml-3'
              onClick={() => setIsOpen(true)}
            />
          )}
        </div>
        <div className='absolute w-full left-0 bottom-0'>
          <PageTransitionLoader loading={loading} />
        </div>
      </div>
      {isOpen && (
        <div className='bg-white fixed h-full top-12 w-full md:hidden px-5'>
          <AgentNavLink
            title='Buy & Rent'
            href={propertiesLink}
            variant={variant}
          />
          <AgentNavLink
            title='About'
            href={`/${getAgentSlug(agentWebsiteResponse, agentSlug)}/about`}
            variant={variant}
          />
          <AgentNavLink
            title='Mortgage Calculator'
            href={`/${getAgentSlug(
              agentWebsiteResponse,
              agentSlug
            )}/mortgage-calculator`}
            variant={variant}
          />
        </div>
      )}
      <Modal isOpen={isContactingMe} onClose={() => setIsContactingMe(false)}>
        <div className='bg-white'>
          <ContactCard agent={agentWebsiteResponse?.agent} />
        </div>
      </Modal>
    </nav>
  );
};

export default AgentNav;
