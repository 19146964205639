import React from 'react';

interface ToolTipProps {
  label: string;
  href?: string;
  children: React.ReactNode;
}

const ToolTip: React.FC<ToolTipProps> = ({ children, label, href }) => {
  return (
    <div className='relative group'>
      {children}
      <div className='absolute top-0 bg-white rounded-sm mt-5 hidden group-hover:flex'>
        <a
          className='text-primary p-1 text-sm'
          href={href}
          target='_blank'
          rel='noreferrer'
        >
          {label}
        </a>
      </div>
    </div>
  );
};

export default ToolTip;
