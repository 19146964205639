import classNames from 'classnames';
import Image from 'next/image';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { BsArrowRight } from 'react-icons/bs';
import {
  AddressRequest,
  AddressRequestCountryEnum,
  CreateAgentLeadRequestAgentLeadTypeEnum,
} from '../openapi/bff';
import BFFApiService from '../services/BFFApiService';
import ErrorService from '../services/ErrorService';
import { Agent, IProperty } from '../types/real-api-types';
import { isRealAgent } from '../utils/AgentUtils';
import { checkAvatarExists } from '../utils/agentWebsiteUtils';
import {
  getThemeBgColorClassName,
  stateAbbreviationToEnumMap,
} from '../utils/DisplayHelpers';
import {
  EMAIL_REGEX,
  formatPhoneNumber,
  parseInitials,
  PHONE_REGEX,
} from '../utils/StringUtils';
import ControlledTextArea from './agent/controlled-inputs/ControlledTextArea';
import ControlledTextInput from './agent/controlled-inputs/ControlledTextInput';
import IconButton from './IconButton';
import { ThemeContext } from './ThemeProvider';

interface FormData {
  name: string;
  email: string;
  phone: string;
  notes: string;
}

interface ContactCardProps {
  agent?: Agent;
  property?: Partial<IProperty>;
  agentLeadType?: CreateAgentLeadRequestAgentLeadTypeEnum;
}
const ContactCard: React.FC<ContactCardProps> = ({
  agent,
  property,
  agentLeadType = CreateAgentLeadRequestAgentLeadTypeEnum.General,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useForm<FormData>();
  const colorTheme = useContext(ThemeContext);
  const isAgentShown = agent && !isRealAgent(agent);

  const onSubmit = async (data: FormData): Promise<void> => {
    try {
      let addressRequest: AddressRequest | null = null;

      if (property) {
        addressRequest = {
          type: '',
          city: property?.city,
          country: AddressRequestCountryEnum.UnitedStates,
          streetAddress1: property?.address1,
          streetAddress2: property?.address2,
          zipOrPostalCode: property?.zip,
          stateOrProvince: property
            ? stateAbbreviationToEnumMap[property?.state]
            : undefined,
        };
      }

      await new BFFApiService().createAgentLead({
        agentId: agent?.id as unknown as string,
        agentLeadType: agentLeadType,
        email: data.email,
        listingId: property?.id,
        message: data.notes,
        mlsNumber: property?.mlsnum,
        name: data.name,
        phoneNumber: data.phone,
        addressRequest,
      });
    } catch (e) {
      ErrorService.notifyIgnoreStatusCodes(
        [403],
        'Unable to post leads to bff',
        e,
        {
          property: { ...property },
          data: { ...data },
        }
      );
    }
  };

  return (
    <form
      className='w-full border'
      onSubmit={handleSubmit(onSubmit)}
      title='contact-form'
    >
      <div
        className={classNames('h-1.5', getThemeBgColorClassName(colorTheme))}
        style={{
          transform: 'perspective(10px) rotateX(-0.8deg)',
        }}
      />

      {isAgentShown && (
        <div className='flex flex-row space-x-2 px-5 pt-5 items-center'>
          <div className='relative w-14 h-14'>
            {checkAvatarExists(agent.cloudinary_image_url) ? (
              <Image
                src={agent.cloudinary_image_url}
                alt='agent-avatar'
                objectFit='cover'
                layout='fill'
                className='w-full h-full rounded-full'
              />
            ) : (
              <span className='inline-flex items-center justify-center rounded-full border-2 h-14 w-14'>
                <span className='font-primary-medium leading-1 text-dark text-lg'>
                  {parseInitials(agent.name)}
                </span>
              </span>
            )}
          </div>
          <ul>
            <li>
              {agent.firstname} {agent.lastname}
            </li>
            {agent.phone_1_display && (
              <li className='font-primary-light'>
                Mobile: {formatPhoneNumber(agent.phone_1_display)}
              </li>
            )}
            <li className='font-primary-light'>
              E-mail:{' '}
              <a className='text-primary' href={`mailto:${agent.email}`}>
                {agent.email}
              </a>
            </li>
          </ul>
        </div>
      )}

      <div className='space-y-4 px-5 w-full p-4'>
        <ControlledTextInput
          name='name'
          variant='gray'
          placeholder='Name'
          control={control}
          rules={{ required: 'Required' }}
        />
        <ControlledTextInput
          name='email'
          variant='gray'
          placeholder='Email'
          control={control}
          rules={{
            required: 'Required',
            pattern: {
              value: EMAIL_REGEX,
              message: 'Invalid Email',
            },
          }}
        />
        <ControlledTextInput
          name='phone'
          variant='gray'
          placeholder='Phone'
          control={control}
          rules={{
            required: 'Required',
            validate: (value) => {
              if (value[0] === '1') {
                return 'Invalid Phone (Omit country code)';
              } else if (!PHONE_REGEX.test(value)) {
                return 'Invalid Phone';
              }

              return undefined;
            },
          }}
        />
        <ControlledTextArea
          name='notes'
          variant='gray'
          placeholder='Message'
          defaultValue={
            property &&
            `I am interested in ${property.address1} ${property.address2}`
          }
          control={control}
          rows={5}
          rules={{
            required: 'Required',
          }}
        />

        <IconButton
          fullWidth
          rightIcon={<BsArrowRight size={20} />}
          title={isSubmitSuccessful ? 'Submitted!' : 'Send Message'}
          isLoading={isSubmitting}
          disabled={isSubmitting || isSubmitSuccessful}
          buttonType='submit'
          variant={colorTheme}
        />
      </div>
    </form>
  );
};

export default ContactCard;
