import React from 'react';
import classNames from 'classnames';
// import { FiX } from 'react-icons/fi';
import { EnumMap } from '../types';

export type SizeVariantType = 'default' | 'small' | 'large';

export interface ModalProps {
  isOpen: boolean;
  onClose(): void;
  size?: SizeVariantType;
  children?: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({
  children,
  isOpen,
  onClose,
  size = 'default',
}) => {
  const sizeVariant: EnumMap<SizeVariantType, string> = {
    default: 'md:w-1/2 lg:w-1/4',
    large: 'md:w-3/5 lg:w-1/3',
    small: 'md:w-2/5 lg:w-1/5',
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className='fixed top-0 right-0 bottom-0 left-0 w-full h-full-window z-50'>
      <div
        className='block absolute top-0 right-0 bottom-0 left-0 bg-black bg-opacity-40 z-0'
        role='button'
        tabIndex={0}
        onClick={onClose}
        onKeyDown={onClose}
      />
      <div className='w-full h-full flex justify-center items-center p-4 lg:p-0'>
        <div
          className={classNames(
            'w-full rounded-lg  z-10 relative',
            sizeVariant[size]
          )}
        >
          {children}
          <button
            onClick={onClose}
            className='absolute top-3 right-3 text-gray-400'
          >
            {/* <FiX size={22} /> */}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
