import classNames from 'classnames';
import React, { HTMLInputTypeAttribute } from 'react';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import ErrorMessage from '../../ErrorMessage';

export type ControlledTextInputModeProps =
  | 'none'
  | 'text'
  | 'tel'
  | 'url'
  | 'email'
  | 'numeric'
  | 'decimal'
  | 'search'
  | undefined;

export type ControlledTextInputVariant = 'white' | 'gray';

export interface ControlledTextInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  type?: HTMLInputTypeAttribute | undefined;
  inputMode?: ControlledTextInputModeProps;
  label?: string;
  readOnly?: boolean;
  placeholder?: string;
  variant: ControlledTextInputVariant;
  IconComponent?: React.ReactElement;
}

const ControlledTextInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  type = 'text',
  inputMode = 'text',
  readOnly,
  placeholder,
  variant,
  IconComponent,
  ...rest
}: ControlledTextInputProps<TFieldValues, TName>): React.ReactElement => {
  const {
    field,
    fieldState: { error },
  } = useController(rest);

  const variantMapClassName: Record<ControlledTextInputVariant, string> = {
    white: 'bg-white',
    gray: 'bg-gray-100',
  };

  return (
    <div className='space-y-1 w-full'>
      {(label || error) && (
        <div className='flex flex-row items-center space-x-3'>
          {label && (
            <label className='inline-block' htmlFor={field.name}>
              {label}
            </label>
          )}
          <ErrorMessage message={error?.message} />
        </div>
      )}
      <div
        className={classNames(
          'flex items-center rounded-sm overflow-hidden space-x-1 px-2 text-gray-500',
          readOnly
            ? 'bg-gray-100 cursor-not-allowed'
            : variantMapClassName[variant],
          !error ? 'border-gray-100' : 'border-red-600'
        )}
      >
        {IconComponent}
        <input
          id={field.name}
          {...field}
          type={type}
          inputMode={inputMode}
          readOnly={readOnly}
          placeholder={placeholder}
          className='grow appearance-none py-2.5 font-primary-light text-gray-500 text-sm leading-none bg-white focus:outline-none focus:ring-0 w-full bg-transparent border-0'
        />
      </div>
    </div>
  );
};

export default ControlledTextInput;
