import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { arePathsSame } from '../../utils/agentWebsiteUtils';

interface AgentBottomLinkProps {
  title: string;
  href: string;
}

const AgentBottomLink: React.FC<AgentBottomLinkProps> = ({ href, title }) => {
  const router = useRouter();

  const isActive = arePathsSame(href, router.asPath);

  return (
    <Link href={href}>
      <a
        className={classNames(
          'font-primary-light',
          isActive ? 'text-gray-600' : 'text-gray-400'
        )}
      >
        {title}
      </a>
    </Link>
  );
};

export default AgentBottomLink;
