import { Agent, RealApiAgentWebsiteResponse } from '../types/real-api-types';

export const REAL_AGENT_SLUG = 'real-agent';

export const isRealAgent = (agent: Agent): boolean =>
  agent?.slug === REAL_AGENT_SLUG;

export const getAgentSlug = (
  agentWebsiteResponse: RealApiAgentWebsiteResponse,
  slug?: string
): string => agentWebsiteResponse?.agent?.slug || slug;
