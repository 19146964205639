import React from 'react';
import { ColorThemeType } from '../../types/real-api-types';
import IconButton from '../IconButton';

interface AgentHrCallToActionProps {
  TitleComponent: React.ReactElement;
  ButtonRightIcon?: React.ReactElement;
  buttonText: string;
  buttonActionUrl?: string;
  onButtonClick?(): void;
  variant?: ColorThemeType;
}

const AgentHrCallToAction: React.FC<AgentHrCallToActionProps> = ({
  TitleComponent,
  ButtonRightIcon,
  buttonText,
  buttonActionUrl,
  onButtonClick,
  variant = 'blue',
}) => {
  return (
    <div className='relative'>
      <div className='px-6'>
        <div className='relative z-10 flex flex-col md:flex-row justify-between items-start md:items-center max-w-full md:max-w-5xl space-y-4 md:space-y-0 p-7 mx-auto border rounded-sm bg-white border-gray-200'>
          <div className='space-y-2 w-full max-w-3xl'>{TitleComponent}</div>
          <div>
            <IconButton
              title={buttonText}
              href={buttonActionUrl}
              buttonType='button'
              variant={variant}
              onClick={() => (!buttonActionUrl ? onButtonClick() : undefined)}
              rightIcon={ButtonRightIcon}
            />
          </div>
        </div>
      </div>
      <div className='w-full h-px absolute top-1/2 z-0 bg-gray-200' />
    </div>
  );
};

export default AgentHrCallToAction;
