import Image from 'next/image';
import React from 'react';
import { Social, LicensedStatesArray } from '../../types/real-api-types';
import Facebook from '../../assets/img/facebook.svg';
import Google from '../../assets/img/google.svg';
import Instagram from '../../assets/img/instagram.svg';
import LinkedIn from '../../assets/img/linkedin.svg';
import Personal from '../../assets/img/personal.svg';
import Logo from '../../assets/img/real_logo.svg';
import Trulia from '../../assets/img/trulia.svg';
import Twitter from '../../assets/img/twitter.svg';
import Yelp from '../../assets/img/yelp.svg';
import Zillow from '../../assets/img/zillow.svg';
import { formatPhoneNumber } from '../../utils/StringUtils';
import AgentBottomLink from './AgentBottomLink';
import ToolTip from './ToolTip';

interface AgentBottomLinksProps {
  buyAndSellLink: string;
  agentSlug: string;
  agentEmail: string;
  agentPhone?: string;
  social: Social;
  licensedStates: LicensedStatesArray[];
}

const AgentBottomLinks: React.FC<AgentBottomLinksProps> = ({
  buyAndSellLink,
  agentSlug,
  licensedStates,
  agentEmail,
  agentPhone,
  social,
}) => {
  return (
    <div className='w-full bg-gray-50'>
      <div className='max-w-6xl mx-auto px-6 flex flex-col md:space-y-6 space-y-4 py-10 md:px-5'>
        <div>
          <Image src={Logo} alt='real_logo' width={48} height={28} />
        </div>
        <div className='md:text-2xl text-lg flex md:flex-row flex-col md:items-center md:justify-around lg:space-x-24 md:space-x-16 md:space-y-0 space-y-2'>
          <AgentBottomLink title='Buy & Rent' href={buyAndSellLink} />
          <AgentBottomLink
            title='Mortgage Calculator'
            href={`/${agentSlug}/mortgage-calculator`}
          />
          <AgentBottomLink title='About' href={`/${agentSlug}/about`} />
        </div>
        <div className='flex md:text-2xl text-lg md:flex-row flex-col md:justify-center md:items-center md:space-x-10 md:py-5'>
          {agentPhone && (
            <a className='font-primary-light text-gray-400'>
              {formatPhoneNumber(agentPhone)}
            </a>
          )}
          <a
            href={`mailto:${agentEmail}`}
            className='font-primary-light text-gray-400'
          >
            {agentEmail}
          </a>
          <div className='flex justify-start py-4'>
            <ul className='flex flex-row space-x-4'>
              {social.facebook_profile_url && (
                <li>
                  <ToolTip label='Facebook' href={social.facebook_profile_url}>
                    <a
                      href={social.facebook_profile_url}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <Image
                        src={Facebook}
                        width={16}
                        height={16}
                        alt='facebook'
                      />
                    </a>
                  </ToolTip>
                </li>
              )}
              {social.instagram_profile_url && (
                <li>
                  <ToolTip
                    label='Instagram'
                    href={social.instagram_profile_url}
                  >
                    <a
                      href={social.instagram_profile_url}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <Image
                        src={Instagram}
                        width={16}
                        height={16}
                        alt='instagram'
                      />
                    </a>
                  </ToolTip>
                </li>
              )}
              {social.linkedin_profile_url && (
                <li>
                  <ToolTip label='Linkedin' href={social.linkedin_profile_url}>
                    <a
                      href={social.linkedin_profile_url}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <Image
                        src={LinkedIn}
                        width={16}
                        height={16}
                        alt='linkedin'
                      />
                    </a>
                  </ToolTip>
                </li>
              )}
              {social.google_business_profile_url && (
                <li>
                  <ToolTip
                    label='Google'
                    href={social.google_business_profile_url}
                  >
                    <a
                      href={social.google_business_profile_url}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <Image src={Google} width={16} height={16} alt='google' />
                    </a>
                  </ToolTip>
                </li>
              )}
              {social.personal_website_url && (
                <li>
                  <ToolTip label='Personal' href={social.personal_website_url}>
                    <a
                      href={social.personal_website_url}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <Image
                        src={Personal}
                        width={16}
                        height={16}
                        alt='personal'
                      />
                    </a>
                  </ToolTip>
                </li>
              )}
              {social.trulia_profile_url && (
                <ToolTip label='Trulia' href={social.trulia_profile_url}>
                  <li>
                    <a
                      href={social.trulia_profile_url}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <Image src={Trulia} width={16} height={16} alt='trulia' />
                    </a>
                  </li>
                </ToolTip>
              )}
              {social.twitter_profile_url && (
                <li>
                  <ToolTip label='Twitter' href={social.twitter_profile_url}>
                    <a
                      href={social.twitter_profile_url}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <Image
                        src={Twitter}
                        width={16}
                        height={16}
                        alt='twitter'
                      />
                    </a>
                  </ToolTip>
                </li>
              )}
              {social.yelp_profile_url && (
                <li>
                  <ToolTip label='Yelp' href={social.yelp_profile_url}>
                    <a
                      href={social.yelp_profile_url}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <Image src={Yelp} width={16} height={16} alt='yelp' />
                    </a>
                  </ToolTip>
                </li>
              )}
              {social.zillow_profile_url && (
                <li>
                  <ToolTip label='Zillow' href={social.zillow_profile_url}>
                    <a
                      href={social.zillow_profile_url}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <Image src={Zillow} width={16} height={16} alt='zillow' />
                    </a>
                  </ToolTip>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className='pb-5'>
          <p className='text-gray-400 font-primary-light text-sm flex justify-center'>
            Licensed States
          </p>
          <div className='flex flex-col items-center justify-center md:space-x-1'>
            {licensedStates.map((item) => {
              return (
                <div
                  className='flex flex-row items-center space-x-1'
                  key={item.state}
                >
                  <p className='text-gray-400 font-primary-light'>
                    State: {item.state},
                  </p>
                  <p className='text-gray-400 font-primary-light'>
                    License: {item.license}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentBottomLinks;
