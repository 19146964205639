import classNames from 'classnames';
import React from 'react';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import ErrorMessage from '../../ErrorMessage';

export type ControlledTextAreaVariant = 'white' | 'gray';

export interface ControlledTextAreaProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  readOnly?: boolean;
  placeholder?: string;
  rows?: number;
  variant: ControlledTextAreaVariant;
}

const ControlledTextArea = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  readOnly,
  placeholder,
  variant,
  rows = 3,
  ...rest
}: ControlledTextAreaProps<TFieldValues, TName>): React.ReactElement => {
  const {
    field,
    fieldState: { error },
  } = useController(rest);

  const variantMapClassName: Record<ControlledTextAreaVariant, string> = {
    white: 'bg-white',
    gray: 'bg-gray-100',
  };

  return (
    <div className='space-y-1 w-full'>
      {(label || error) && (
        <div className='flex flex-row items-center space-x-3'>
          {label && (
            <label className='inline-block' htmlFor={field.name}>
              {label}
            </label>
          )}
          <ErrorMessage message={error?.message} />
        </div>
      )}
      <textarea
        id={field.name}
        {...field}
        readOnly={readOnly}
        placeholder={placeholder}
        rows={rows}
        className={classNames(
          'rounded-sm overflow-hidden space-x-1 px-4 appearance-none py-2.5 font-primary-light text-gray-500 text-sm leading-none bg-white focus:outline-none focus:ring-0 w-full',
          readOnly
            ? 'bg-gray-100 cursor-not-allowed'
            : variantMapClassName[variant],
          !error ? 'border-gray-100' : 'border-red-600'
        )}
      />
    </div>
  );
};

export default ControlledTextArea;
