import React from 'react';
import classNames from 'classnames';
import { FiLoader } from 'react-icons/fi';
import { EnumMap } from '../types';
import { ColorThemeType } from '../types/real-api-types';
import LinkWithAnchor from './LinkWithAnchor';

type ButtonVariantType = 'primary' | 'secondary' | 'outline' | ColorThemeType;

export interface IconButtonProps {
  rightIcon?: React.ReactElement;
  leftIcon?: React.ReactElement;
  title?: string;
  href?: string;
  target?: string;
  onClick?(): void;
  variant?: ButtonVariantType;
  buttonType?: 'submit' | 'button' | 'reset';
  fullWidth?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
}

const IconButton: React.FC<IconButtonProps> = ({
  leftIcon,
  rightIcon,
  onClick,
  href,
  target,
  title,
  variant = 'primary',
  buttonType = 'button',
  fullWidth,
  isLoading,
  disabled,
}) => {
  const ButtonVariantsClassMap: EnumMap<ButtonVariantType, string> = {
    primary: 'bg-primary text-white',
    secondary: 'bg-gray-100 border border-gray-300 text-black',
    outline: 'border border-gray-500 bg-white text-dark',
    green: 'bg-theme-green text-white',
    black: 'bg-theme-black text-white',
    blue: 'bg-theme-blue text-white',
    red: 'bg-theme-red text-white',
    yellow: 'bg-theme-yellow text-white',
    magenta: 'bg-theme-magenta text-white',
  };

  const Container = href
    ? href.startsWith('http')
      ? 'a'
      : LinkWithAnchor
    : 'span';

  const LoaderIconComponent = <FiLoader size={18} className='animate-spin' />;
  const LeftIconComponnet =
    leftIcon && isLoading ? LoaderIconComponent : leftIcon;
  const RightIconComponent =
    rightIcon && isLoading ? LoaderIconComponent : rightIcon;

  return (
    <Container href={href} target={target}>
      <button
        type={buttonType}
        onClick={onClick}
        disabled={disabled}
        className={classNames(
          'flex flex-row font-primary-medium rounded-sm py-2 px-4 items-center focus:outline-none hover:opacity-90 ',
          ButtonVariantsClassMap[variant],
          {
            'w-full justify-center': fullWidth,
            'cursor-wait': isLoading,
            'cursor-not-allowed': !isLoading && disabled,
          }
        )}
      >
        <div className='items-center flex flex-row space-x-1'>
          {LeftIconComponnet && (
            <span className={classNames('flex items-center')}>
              {LeftIconComponnet}
            </span>
          )}
          {title && (
            <span className={classNames('px-1 items-center whitespace-nowrap')}>
              {title}
            </span>
          )}
        </div>
        {RightIconComponent && (
          <span className={classNames('flex items-center')}>
            {RightIconComponent}
          </span>
        )}
      </button>
    </Container>
  );
};

export default IconButton;
