import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';
import { arePathsSame } from '../../utils/agentWebsiteUtils';
import { getThemeTextColorClassName } from '../../utils/DisplayHelpers';
import { ThemeContext } from '../ThemeProvider';

interface AgentNavLinkProps {
  title: string;
  href: string;
  variant?: 'primary' | 'transparent';
}

const AgentNavLink: React.FC<AgentNavLinkProps> = ({
  title,
  href,
  variant,
}) => {
  const router = useRouter();

  const isActive = arePathsSame(href, router.asPath);

  const colorTheme = useContext(ThemeContext);

  return (
    <Link href={href}>
      <a className='block md:inline-block mt-5 md:mt-0 md:mr-5 md:h-full'>
        <span
          className={classNames(
            'inline-block md:block font-primary-regular text-2xl md:text-base md:mt-3 mt:0',
            {
              'text-gray-400': variant === 'primary',
              'md:text-white text-gray-400': variant === 'transparent',
              [getThemeTextColorClassName(colorTheme)]: isActive,
            }
          )}
        >
          {title}
        </span>
      </a>
    </Link>
  );
};

export default AgentNavLink;
