import qs from 'qs';
import { useCallback, useEffect, useState } from 'react';
import {
  PropertiesFilterType,
  RealApiAgentWebsiteResponse,
} from '../types/real-api-types';
import {
  getPropertiesFilterFromAgentWebsiteResponse,
  isOldAgentWebsiteRespose,
} from '../utils/PropertiesUtils';
import { getAgentSlug } from '../utils/AgentUtils';
import { useGoogleMapsAPILoader } from './useGoogleMapsAPILoader';

export const usePropertiesFilterLink = (
  agentWebsiteResponse: RealApiAgentWebsiteResponse,
  agentSlug?: string
): string => {
  const [propertyFilter, setPropertyFilter] = useState<PropertiesFilterType>();

  const isLoaded = useGoogleMapsAPILoader();

  const fetchProperties = useCallback(async () => {
    if (!isOldAgentWebsiteRespose(agentWebsiteResponse)) {
      const filter = await getPropertiesFilterFromAgentWebsiteResponse(
        agentWebsiteResponse
      );

      setPropertyFilter(filter);
    }
  }, [agentWebsiteResponse]);

  useEffect(() => {
    if (isLoaded) {
      fetchProperties();
    }
  }, [fetchProperties, isLoaded]);

  return `/${getAgentSlug(
    agentWebsiteResponse,
    agentSlug
  )}/listings?${qs.stringify({
    ...propertyFilter,
  })}`;
};
